import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Table, Title, ViewInput} from './../../GlobalStyles';
import Loader from '../../components/Loader';
import { constructNow, format } from 'date-fns';
import { MdAdd, MdCurrencyExchange } from 'react-icons/md';
import { toast } from 'react-toastify';
import { AutoComplete, Button, Input, Modal, Pagination, Select } from 'antd';
import api from '../../services/api';

const { TextArea } = Input;

const User = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [usersC, setUsersC] = useState([]);
  const [userTransfer, setUserTransfer] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [page, setPage] = useState(0);
  const [transactions, setTransactions] = useState(0);
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [description, setDescription] = useState("")
  const [value, setValue] = useState("")
  const [select, setSelect] = useState("")
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isOpenInteraction, setIsOpenInteraction] = useState(false)
  const [isOpenTransfer, setIsOpenTransfer] = useState(false)

  async function loadUser() {
    await api.get(`/user/${id}?page=${page}`).then((response) => {
      setUser(response.data)
      setIsLoading(false)
    })
  }

  async function loadUsers() {
    await api.get("/users?all=true").then((response) => {
      let users = response.data.users
      users.map((item) => {
        item.value = item.name
        item.label = item.name
      })
      setUsers(users)
      setUsersC(users)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  useEffect(() => {
    loadUser()
    loadUsers()
  }, []);


  async function createBonus() {
    setIsLoadingModal(true)
    if (!value || !description){
      toast.warning("Descrição e Valor são obrigatórios");
    } else {
      await api.post(`/bonus`, {
        name: "Bonificação",
        value: value,
        description: description,
        user_id: id,
      }).then((response) => {
        toast.success("Bonificação criada com sucesso")
        setIsOpenInteraction(false)
        loadUser()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function createPenalty() {
    setIsLoadingModal(true)
    if (!value || !description){
      toast.warning("Descrição e Valor são obrigatórios");
    } else {
      await api.post(`/penalty`, {
        name: "Penalização",
        value: value,
        description: description,
        user_id: id,
      }).then((response) => {
        toast.success("Penalização criada com sucesso")
        setIsOpenInteraction(false)
        loadUser()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function transferPoints() {
    setIsLoadingModal(true)
    if (!value || !id || !userTransfer.id){
      toast.warning("Usuário recebedor e Valor são obrigatórios");
    } else {
      await api.post(`/transfer`, {
        user_send_id: id,
        value: value,
        user_receive_id: userTransfer.id,
      }).then((response) => {
        toast.success("Transferencia realizada com sucesso")
        setIsOpenTransfer(false)
        loadUser()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }
  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Title>
            <h2>Dados do Usuário</h2>
            <div>
              <Button type="primary" onClick={() => {
                  setSelect("")
                  setValue("")
                  setDescription("")
                  setIsOpenInteraction(true)
              }}>
                <MdAdd size="20" color="#fff" />
                <span>Penalização e Bonificação</span>
              </Button>
              <Button type="primary" onClick={() => {
                  setValue("")
                  setDescription("")
                  setUserTransfer("")
                  setNameUser("")
                  setIsOpenTransfer(true)
              }}>
                <MdCurrencyExchange size="17" color="#fff" />
                <span>Transferir Pontos</span>
              </Button>
            </div>
          </Title>
          <img style={{ width: 120, height: 120, marginBottom: 15, borderRadius: 2}} src={user.photo_url || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
          <p><strong>Nome: </strong>{user.name}</p>
          <p><strong>Email: </strong>{user.email}</p>
          <p><strong>Pontos de Resgate: </strong>{user.points} pontos</p>
          <p><strong>Pontos de Interação: </strong>{user.points_interactions} pontos</p>
          <p><strong>Aniversário: </strong>{format(constructNow(user.birthday), "dd/MM/yyyy")}</p>
          <p><strong>Telefone: </strong>{user.phone_number ? user.phone_number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : "Não cadastrado"}</p>
          <p><strong>Observação: </strong>{user.observation || "Nenhuma observação"}</p>
           {
              user.transactions.length ? (
                <>
                <Table>
                  <tbody>
                      {
                      user.transactions.map(transaction => (
                          <tr key={transaction.id}>
                            <td>{transaction.description}</td>
                            <td style={{color: transaction.operation != "entrada" ? "#d63211" : "#1eb019"}}>{transaction.value} pontos</td>
                            <td>{format(new Date(transaction.created_at), "dd/MM/yyyy HH:mm")}</td>
                          </tr>
                        ))
                      }
                  </tbody>
                  {user.transactions.length != 0 && (
                    <thead>
                      <tr>
                        <td>Tipo</td>
                        <td>Valor</td>
                        <td>Data</td>
                      </tr>
                    </thead>)}
                  </Table>
                  <center style={{marginTop: 15}}>
                    <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                      setPage(page - 1)
                   }} total={transactions} pageSize={30} 
                   showTotal={(total) => `${total} transações`}/>
                  </center>
                </>
              )
              : (
                <div className="error">
                  <p>Nenhuma transação encontrada</p>
                </div>
              )}
        </>
      )}

      <Modal title={`Bonificação ou Penalidade`} width={500}
        confirmLoading={isLoadingModal} open={isOpenInteraction}
        okText={`ENVIAR ${select == "bonus" ? "BONIFICAÇÃO" : "PENALIZAÇÃO"}`} cancelText="FECHAR" onOk={() => { 
          if (select) {
            if (select == "bonus") {
              createBonus()
            } else {
              createPenalty()
            }
          } else {
            toast.warn("Selecione o tipo de interação com o usuário")
          }
          
      }} onCancel={() => {
        setIsOpenInteraction(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
          <ViewInput>
            <p>Tipo de Interação*</p>
            <Select
              placeholder="nome"
              style={{width: "100%", textAlign: "left"}}
              value={select || null}
              options={[{
                label: "Bonificação",
                value: "bonus"
              }, {
                label: "Penalização",
                value: "penalty"
              }]}
              onChange={(value) => setSelect(value)} />
          </ViewInput>
          <ViewInput>
            <p>Pontos*</p>
            <Input
              type='number'
              placeholder="pontos"
              value={value}
              onChange={(event) => setValue(parseInt(event.target.value))} />
          </ViewInput>
          <ViewInput>
            <p>Descrição</p>
            <TextArea
              placeholder="deixe uma descrição"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={description}
              onChange={(event) => setDescription(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
      <Modal title={`Transferencia de Pontos`} width={500}
        confirmLoading={isLoadingModal} open={isOpenTransfer}
        okText={`ENVIAR PONTOS`} cancelText="FECHAR" onOk={() => { 
          transferPoints()
      }} onCancel={() => {
        setIsOpenTransfer(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
          <ViewInput>
            <p>Quem receberá os pontos*</p>
            <AutoComplete
              style={{width: "100%", textAlign: "left"}}
              options={users.filter((item)=>{ return item.id != id})}
              value={userTransfer || nameUser}
              notFoundContent={(<>Nenhum usuário encontrado</>)}
              onSelect={(text, client) => {
                setUserTransfer(client)
                setNameUser(text);
              }}
              onSearch={(text) => {
                  if (userTransfer) {
                    if (text.toUpperCase() != userTransfer.name.toUpperCase()) {
                      setUserTransfer("")
                    }
                  }
                  
                  setNameUser(text)
                  setUsers(usersC.filter((item) => {
                      return String(item.name).toUpperCase().indexOf(text.toUpperCase()) != -1
                  }))
              }}
              placeholder="procurar por nome"/>
          </ViewInput>
          <ViewInput>
            <p>Pontos para Transferir*</p>
            <Input
              type='number'
              placeholder="pontos"
              value={value}
              onChange={(event) => setValue(parseInt(event.target.value))} />
          </ViewInput>
        </div>
      </Modal>
    </Container>
  );
};

export default User;
