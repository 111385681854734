import React, { useEffect, useState } from 'react';
import {
  MdAddPhotoAlternate,
  MdArrowBack
} from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import api from '../../services/api';
import { Container, Scroll, Title, ViewInput, Table} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Input, Select } from 'antd';


const Ranking = ({newPost}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRanking, setIsLoadingRanking] = useState(false);
  const [rooms, setRooms] = useState([])
  const [ranking, setRanking] = useState([])
  const [room_id, setRoom_id] = useState("")

  useEffect(() => {
    loadRooms()
  }, []);

  useEffect(() => {
    if (room_id) {
      loadRanking()
    }
  }, [room_id]);


async function loadRooms() {
  await api.get(`/rooms?all=true`).then((response) => {
   let rooms = response.data.rooms
   rooms.map((item)=>{
      item.value = item.id
      item.label = item.name
   })
   setRooms(rooms)
  }).catch(({ response }) => {
    if (response) {
      if (response.data) {
        if (response.data.message) {
          toast.warn(response.data.message)
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    } else {
      toast.error("Erro Interno. verifique sua conexão e tente novamente")
    }
  })
  setIsLoading(false);
}

  async function loadRanking() {
    setIsLoadingRanking(true)
   await api.get(`/ranking/${room_id}`).then((response) => {
     setRanking(response.data)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoadingRanking(false);
  }

  return (
    <Container>
      <center>
        {isLoading ? <Loader /> : (
          <div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: 800, marginBottom: 25 }}>
            <Title>
              <h2>Ranking Mensal</h2>
            </Title>
            <ViewInput>
              
            <p>Selecione a Sala*</p>
            <Select
                    options={rooms}
                    placeholder="selecione a sala"
                          style={{width: "100%", textAlign: "left"}}
                          value={room_id || null}
                          onChange={(room) => setRoom_id(room)} />
              </ViewInput>
              {
              room_id ? (
                <>
                {isLoadingRanking ? <Loader /> : <>
            
            {ranking.length ? (
                <>
                <Scroll>
                  <Table style={{minWidth: 300}}>
                    <thead>
                      <tr>
                            <td style={{ width: 40, textAlign: "center" }}>
                              Posição
                        </td>
                        <td>Nome</td>
                        <td style={{minWidth: 100}}>Pontos</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ranking.map((user, index) => (
                          <tr key={user.id}>
                            <td style={{ width: 40, textAlign: "center" }}>
                              {index+1}
                            </td>
                            <td>{user.name}</td>
                            <td  style={{ width: 100, textAlign: "center" }}>{user.points} Pontos</td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </Table>
                  </Scroll>
                  </>
                )
                : (
                  <div className="error">
                    <p>Nenhum usuário encontrado</p>
                  </div>
                )}
              </>}
                </>
              ): (
                <div className="error">
                  <p>Selecione uma sala</p>
                </div>
              )
            }
            
        </div>
        )}
      </center>
    </Container>
  );
};

export default Ranking;
