import styled from 'styled-components';

export const Row = styled.table`
  width: 100%;
  margin-top: 25px;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #001B22;

  .post{
    width: 100%;
    max-width: 450px;
    display: flex;
    margin: 0 15px 15px 0;
    padding: 15px;
    border-radius: 10px;;
    flex-direction: column;
    align-items: center;
    background: #FFF;

    @media (max-width: 570px){
      max-width: 100%;
      margin: 0 0 15px 0;
    }

    img{
      width: 100%;
      height: 250px;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    h3{
      width: 100%;
      text-align: left;
      margin: 5px 0;
    }

    p{
      width: 100%;
      text-align: left;
      margin: 5px 0;
      white-space: pre-line;
      word-break: break-word;
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 5; /* number of lines to show */
           line-clamp: 5; 
   -webkit-box-orient: vertical;
    }
  }

  button {
    border: none; 
    width: 48%;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

`;
