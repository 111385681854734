import React, { useEffect, useState } from 'react';
import {
 MdAdd, MdAddPhotoAlternate, MdDelete, MdEdit, MdStore, MdVisibility,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { ExclamationCircleFilled } from '@ant-design/icons';

import api from '../../services/api';
import { Container, Cards, Card, Title, ViewInput, InputTel, Table, Scroll} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination, Row, Switch } from 'antd';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)

const { confirm } = Modal;

const { TextArea } = Input;

const Products = () => {

  const navigate = useNavigate()

  const [products, setProducts] = useState([]);
  const [productsTotal, setProductsTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [value, setValue] = useState("")
  const [amount, setAmount] = useState("")
  const [is_bomstar, setIs_bomstar] = useState(true)
  const [is_social, setIs_social] = useState(false)
  const [page, setPage] = useState(0)
  const [photo, setPhoto] = useState("")
  const [id, setId] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)


  useEffect(() => {
    loadProducts()
  }, [page]);

  async function loadProducts() {
   await api.get(`/products?page=${page}`).then((response) => {
     setProducts(response.data.products)
     setProductsTotal(response.data.productsTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function createUser() {
    setIsLoadingModal(true)
    if (!name || !value || !amount || !photo || !description){
      toast.warning("Nome, Valor, Quantidade, Descrição e Foto são obrigatórios");
    } else {
      
      const data = new FormData();
      data.append('name', name);
      data.append('value', value);
      data.append('amount', amount);
      data.append('is_social', is_social);
      data.append('is_bomstar', is_bomstar);
      data.append('description', description);
      if (photo.name) {
        data.append('file', photo, photo.name);
      }
      await api.post(`/product`, data).then((response) => {
        toast.success("Produto criado com sucesso")
        setIsOpen(false)
        loadProducts()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editUser() {
    setIsLoadingModal(true)
    if (!name || !value || !amount || !description){
      toast.warning("Nome, Valor, Quantidade e Descrição são obrigatórios");
    } else {

      const data = new FormData();
      data.append('name', name);
      data.append('value', value);
      data.append('amount', amount);
      data.append('is_social', is_social);
      data.append('is_bomstar', is_bomstar);
      data.append('description', description);
      if (photo.name) {
        data.append('file', photo, photo.name);
      }
      await api.put(`/product/${id}`, data).then((response) => {
        toast.success("Produto editado com sucesso")
        setIsOpen(false)
        loadProducts()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteProduct(product) {
    await api.delete(`/product/${product.id}`).then(response => {
      toast.success("Produto deletado com sucesso")
      loadProducts()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  return (
    <Container>
      <Title>
        <h2>Produtos</h2>
        <div>
            <Button type="primary" onClick={() => {
              setId("")
              setName("")
              setAmount("")
              setValue("")
              setIs_social(false)
              setIs_bomstar(true)
              setPhoto("")
              setDescription("")
              setIsOpen(true)
            }}>
            <MdAdd size="20" color="#fff" />
            <span>Novo Produto</span>
          </Button>
        </div>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <MdStore color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={productsTotal} />
            <p>Total</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {products.length ? (
              <>
              <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td style={{ width: 50 }}>
                      </td>
                      <td>Nome</td>
                      <td style={{minWidth: 150}}>Pontos de Resgate</td>
                      <td style={{minWidth: 80}}>Estoque</td>
                      <td style={{minWidth: 80}}>Proj. Social</td>
                      <td style={{minWidth: 80}}>Proj. Bomstar</td>
                      <td style={{ width: 80 }}>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      products.map(product => (
                        <tr key={product.id}>
                          <td style={{ width: 40 }}>
                            <img style={{ width: 30, height: 30, borderRadius: 2}} src={product.photo_url || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                          </td>
                          <td>{product.name}</td>
                          <td>{product.value} Pontos</td>
                          <td>{product.amount}x</td>
                          <td>{product.is_social ? "Visivel" : "Oculto"}</td>
                          <td>{product.is_bomstar ? "Visivel" : "Oculto"}</td>
                          
                        <td style={{width: 120}}>
                            <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                            <MdEdit
                              onClick={() => {
                                setId(product.id)
                                setName(product.name)
                                setAmount(product.amount)
                                setDescription(product.description)
                                setValue(product.value)
                                setIs_social(product.is_social)
                                setIs_bomstar(product.is_bomstar)
                                setPhoto({photo_url: product.photo_url})
                                setIsOpen(true)
                              }}
                                style={{ cursor: "pointer", marginLeft: 10 }}
                                size={22}
                                color={"#001B22"}
                              />
                              <MdDelete
                                onClick={() => {
                                  confirm({
                                    title: 'Deseja excluir o produto?',
                                    icon: <ExclamationCircleFilled/>,
                                    content: `Após essa ação, o produto ${product.name} será excluido.`,
                                    onOk() {
                                      deleteProduct(product);
                                    },
                                    onCancel() {},
                                      cancelText: "Cancelar",
                                  });
                                }}
                                  style={{ cursor: "pointer", marginLeft: 6 }}
                                  size={22}
                                  color={"#001B22"}
                                />
                                </div>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </Table>
                </Scroll>
                  <center style={{marginTop: 15}}>
                    <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                      setPage(page - 1)
                   }} total={productsTotal} pageSize={30} 
                   showTotal={(total) => `${total} produtos`}/>
                  </center>
                </>
              )
              : (
                <div className="error">
                  <p>Nenhum produto encontrado</p>
                </div>
              )}
        </>
      )}

      {isLoading && <Loader />}
      <Modal title={id ? "Editar Produto" : "Novo Produto"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={id ? "EDITAR PRODUTO" : "CADASTRAR PRODUTO"} cancelText="FECHAR" onOk={() => { 
        if (id) {
          editUser()
        } else {
          createUser()
        }  
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
        <div style={{ marginTop: 0, display: "flex", width: "100%", alignItems: "end", height: 120, borderRadius: 10, marginBottom: 25, marginBottom: 20, justifyContent: "center" }}>
            <img style={{ width: 120, marginLeft: 50, height: 120, borderRadius: 10, background: "#fff", }} src={photo.photo_url ? photo.photo_url : "https://upload.wikimedia.org/wikipedia/commons/1/14/Product_sample_icon_picture.png"} />
            <button style={{
              width: 50, height: 50, border: "none",
              background: "transparent"
            }}>
              <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                <MdAddPhotoAlternate color="#001B22" size={25} />
                <input id="avatar" style={{ display: "none" }} type="file" accept="image/png,image/jpeg"   onChange={(file) => {
                  let photo = file.target.files[0]
                  photo.photo_url = URL.createObjectURL(file.target.files[0])
                  setPhoto(photo)
                }
                }
                />
              </label>
            </button>
          </div>
          <ViewInput>
            <p>Nome*</p>
            <Input
              placeholder="nome"
              value={name}
              onChange={(event) => setName(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Descrição*</p>
            <TextArea
              placeholder="descrição do produto"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={description}
              onChange={(event) => setDescription(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Pontos para Resgate*</p>
            <Input
              type='number'
              placeholder="pontos para resgate"
              value={value}
              onChange={(event) => setValue(parseInt(event.target.value))} />
          </ViewInput>
          <ViewInput>
            <p>Quantidade*</p>
            <Input
              type='number'
              placeholder="quantidade"
              value={amount}
              onChange={(event) => setAmount(parseInt(event.target.value))} />
          </ViewInput>
          <Row style={{justifyContent: "space-between"}}>

          <ViewInput style={{width: "48%", textAlign: "left"}}>
            <p>Exibir em Projeto BomStar*</p>
            <Switch
              placeholder="quantidade"
              value={is_bomstar}
              onChange={(value) => setIs_bomstar(value)} />
            </ViewInput>
            
          <ViewInput style={{width: "48%", textAlign: "left"}}>
            <p>Exibir em Projeto Social*</p>
            <Switch
              placeholder="quantidade"
              value={is_social}
              onChange={(value) => setIs_social(value)} />
          </ViewInput>
          </Row>
        </div>
      </Modal>
    </Container>
  );
};

export default Products;
