import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

const RouterWrapper = () => {
  const user = localStorage.getItem('@bomstar-dash:user');

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]); 

  return !user ? (
      <>
          <Outlet />
      </>
  ) : (<></>)
};

export default RouterWrapper;
