import styled from 'styled-components';

export const Row = styled.table`
  width: 100%;
  margin-top: 25px;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #001B22;

  button{
    border-color: #B40000;
    border-style: solid;
    transition: background-color 1s ease; 
  }
  
  .infos:hover{
    background: #B40000;
      color: #fff;

  }

  .infos{
    width: calc(33% - 20px);
    display: flex;
    margin: 0 20px 20px 0;
    padding: 15px 15px;
    border-radius: 10px;;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #B40000;
    cursor: pointer;

    @media (max-width: 600px){
      width: calc(50% - 20px);
      max-width: 100%;
    }

    @media (max-width: 350px){
      width: 100%;
      max-width: 100%;
      margin: 0 0 15px 0;
    }
    p{
      width: 100%;
      text-align: center;
      margin: 5px 0;
      font-size: 15px
    }

    span{
      width: 100%;
      text-align: center;
      margin: 5px 0 10px 0;
      font-size: 25px;
      font-weight: bold;
    }
  }
`;

