import React, { useEffect, useState } from 'react';
import {
 MdAdd, MdDelete, MdEdit, MdFeed
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { Row } from './styles';
import api from '../../services/api';
import { Container, Cards, Card, Title } from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination } from 'antd';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)

const { confirm } = Modal;

const Posts = () => {

  const navigate = useNavigate()

  const [posts, setPosts] = useState([]);
  const [postsTotal, setPostsTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0)

  useEffect(() => {
    loadPosts()
  }, [page]);

  async function loadPosts() {
   await api.get(`/posts?page=${page}`).then((response) => {
     setPosts(response.data.posts)
     setPostsTotal(response.data.postsTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function deletePost(post) {
    await api.delete(`/post/${post.id}`).then(response => {
      toast.successa("Postagem deletado com sucesso")
      loadPosts()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  return (
    <Container>
      <Title>
        <h2>Mural</h2>
        <div>
            <Button type="primary" onClick={() => {
              navigate("/nova-postagem")
            }}>
            <MdAdd size="20" color="#fff" />
            <span>Nova Postagem</span>
          </Button>
        </div>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <MdFeed color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={postsTotal} />
            <p>Total de Postagens</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {posts.length ? (
              <>
               <Row>
                    {
                      posts.map(post => (
                        <div key={post.id} className='post'>
                          <img src={post.photo_url} />
                          <h3>{post.title}</h3>
                          <p>{post.text}</p>
                          <row style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Button
                              type="primary"
                              style={{ background: "#CFBE06", width: "56%" }}
                              onClick={() => {
                                navigate(`/postagem/${post.id}`)
                              }}
                            >
                              <MdEdit
                                style={{ cursor: "pointer", marginRight: 6 }}
                                size={20}
                              color={"#fff"}
                            />
                              Editar
                            </Button>
                            <Button
                              type="primary"
                              style={{ background: "#c22", width: '40%' }}
                              onClick={() => {
                                confirm({
                                  title: 'Deseja excluir a postagem?',
                                  icon: <ExclamationCircleFilled/>,
                                  content: `Após essa ação, a postagem ${post.title} será excluida.`,
                                  onOk() {
                                    deletePost(post);
                                  },
                                  onCancel() {},
                                    cancelText: "Cancelar",
                                });
                              }}
                            >
                            <MdDelete
                                style={{ cursor: "pointer", marginRight: 6 }}
                                size={20}
                                color={"#fff"}
                              />
                              Excluir
                            </Button>
                          </row>
                        </div>
                      ))
                    }
                  </Row>
                  <center style={{marginTop: 15}}>
                    <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                      setPage(page - 1)
                   }} total={postsTotal} pageSize={12} 
                   showTotal={(total) => `${total} postagens`}/>
                  </center>
                </>
              )
              : (
                <div className="error">
                  <p>Nenhuma postagem encontrada</p>
                </div>
              )}
        </>
      )}

      {isLoading && <Loader />}
    </Container>
  );
};

export default Posts;
