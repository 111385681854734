import React, { useEffect, useState } from 'react';
import {
  FaNetworkWired
} from 'react-icons/fa';
import CountUp from 'react-countup';

import api from '../../services/api';
import { Container, Cards, Card, Title, Table, Scroll} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Pagination } from 'antd';

const Interactions = () => {


  const [interactions, setInteractions] = useState([]);
  const [interactionsTotal, setInteractionsTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0)


  useEffect(() => {
    loadInteractions()
  }, [page]);

  async function loadInteractions() {
   await api.get(`/admin-interactions?page=${page}`).then((response) => {
     setInteractions(response.data.interactions)
     setInteractionsTotal(response.data.interactionsTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }


  return (
    <Container>
      <Title>
        <h2>Interações</h2>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <FaNetworkWired color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={interactionsTotal} />
            <p>Total</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {interactions.length ? (
              <>
              <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td style={{ width: 50 }}>
                      </td>
                      <td style={{ width: 250 }}>Enviador por</td>
                      <td style={{ width: 50 }}>
                      </td>
                      <td style={{ width: 250 }}>Para</td>
                      <td style={{minWidth: 150}}>Interação</td>
                      <td style={{minWidth: 80}}>Status</td>
                      <td style={{minWidth: 80}}>Pontos</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      interactions.map(interaction => (
                        <tr key={interaction.id}>
                          <td style={{ width: 50 }}>
                            <img style={{ width: 30, height: 30, borderRadius: 2}} src={interaction.user_send.photo_url || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                          </td>
                          <td  style={{ width: 250 }}>{interaction.user_send.name}</td>
                          <td style={{ width: 50 }}>
                            <img style={{ width: 30, height: 30, borderRadius: 2}} src={interaction.user_receive.photo_url || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                          </td>
                          <td  style={{ width: 250 }}>{interaction.user_receive.name}</td>
                          <td>{interaction.name}</td>
                          <td style={{textTransform: "uppercase", fontSize: 12}}>{interaction.status}</td>
                          <td>{interaction.reward} Pontos</td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </Table>
                </Scroll>
                  <center style={{marginTop: 15}}>
                    <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                      setPage(page - 1)
                   }} total={interactionsTotal} pageSize={30} 
                   showTotal={(total) => `${total} interações`}/>
                  </center>
                </>
              )
              : (
                <div className="error">
                  <p>Nenhuma interação encontrada</p>
                </div>
              )}
        </>
      )}

      {isLoading && <Loader />}
    </Container>
  );
};

export default Interactions;
