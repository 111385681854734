import React, { useState } from 'react';
import { MenuOutlined } from "@ant-design/icons";

import { Button, Drawer } from 'antd';
import logo from "./../../images/logo.png"
import "./style.css"
import Menu from '../Menu';
import { useAuth } from '../../hooks/AuthContext';
import photoUser from "./../../images/user.png"

const Header = () => {

  const { user } = useAuth()
  const [visible, setVisible] = useState(false);

  return (
    <header className="header">
      <Button
        className="menu"
        type="primary"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />
      <Drawer
        title="Menu"
        placement="left"
        width={250}
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
          styles={{
            body: {
              background: "#960000",
              padding: 0
            },
            header: {
              padding: "12px 10px",
              background: "#960000",
            },
        }}
        visible={visible} 
      >
        <Menu />
      </Drawer>
      <img src={logo} className="logo" alt="logo" />
      <div className='profile'>
        <img src={user.photo ? user.photo_url : photoUser} />
        <h3>Olá, {user.name}</h3>
      </div>
    </header>
  );
};

export default Header;
