import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Router from './routers';
import { ConfigProvider } from 'antd';
import { AuthProvider } from './hooks/AuthContext';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
        // Seed Token
        colorPrimary: '#B40000',
        colorPrimaryHover: "#333",
        borderRadius: 2,
        colorFill: '#B40000',
        colorIcon: '#B40000',
        colorBgContainer: '#fff',
      },
      components: {
        Button: {
          colorPrimary: '#B40000',
          colorPrimaryHover: "#B40000CC",
          boxShadow: 0
        },
        Input: {
          lineWidthFocus: 1,
        }
      },
      }}>
      <AuthProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
