import React, { useEffect, useState } from 'react';
import {
  MdCake
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

import api from '../../services/api';
import { Container, Cards, Card, Title, Table, Scroll} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Pagination } from 'antd';
import { format } from 'date-fns';

const Birthdays = () => {
  const [birthdays, setBirthdays] = useState([]);
  const [birthdaysTotal, setBirthdaysTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0)

  useEffect(() => {
    loadBirthdays()
  }, [page]);

  async function loadBirthdays() {
   await api.get(`/birthdays`).then((response) => {
     setBirthdays(response.data)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  return (
    <Container>
      <Title>
        <h2>Aniversários</h2>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <MdCake color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={birthdays.length} />
            <p>Aniversariantes nos próximos 30 dias</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {birthdays.length ? (
              <>
              <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td style={{ width: 50 }}>
                      </td>
                      <td>Nome</td>
                      <td>Aniversário</td>
                      <td>Idade</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      birthdays.map(user => {
                        user.birthday = new Date(user.birthday)
                        user.birthdayNew = new Date(new Date().getFullYear(), user.birthday.getMonth(), user.birthday.getDate())
                        return (
                          <tr key={user.id}>
                            <td style={{ width: 40 }}>
                              <img style={{ width: 30, height: 30, borderRadius: 2 }} src={user.photo_url || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                            </td>
                            <td>{user.name}</td>
                            <td>{format(user.birthdayNew, "dd/MM/yyyy")} - {user.interval == 0 ? "Hoje" : `Falta ${user.interval} dias`}</td>
                            <td>{format(user.birthday, "dd/MM/yyyy")} - {user.year} anos</td>
                            <td style={{ width: 120 }}>
                              <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                                <Button onClick={() => {
                                  let link = `https://api.whatsapp.com/send?phone=55${user.phone_number}&text=Olá ${user.name}, parabéns pelo seu dia`
                                  window.location.assign(link);
                                }}>
                                  Enviar Mensagem
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </Scroll>
                </>
              )
              : (
                <div className="error">
                  <p>Nenhum aniversariante encontrado</p>
                </div>
              )}
        </>
      )}

      {isLoading && <Loader />}
    </Container>
  );
};

export default Birthdays;
