import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #C62828;

    button {
      flex: 1;
      margin: 20px 0 5px 0;
      border-radius: 8px;
      min-height: 40px;
      max-height: 40px;
    }

    input{
      border-radius: 4px;
    }
`;

export const Content = styled.div`
  width: 90%;
  max-width: 450px;
  min-height: 450px;
  display: flex;
  padding: 50px 15px;
  background: #f0f0f0;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;

  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    margin: 40px 0px 50px 0;
    img {
      max-width: 85%;
      width: 300px;
      border-radius: 8px;
    }
  }

  .input{
    width: calc(100% - 10px);
    margin: 15px 0 5px 0; 
    padding: 0;
    color: #C62828CC;

    p{
      font-size: 14px;
      margin: 0 0 5px 0;
    }
  }

`;
