import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import RouterWrapper from './RouterWrapper';

import Login from '../pages/Login';
import Home from '../pages/Home';
import User from '../pages/User';
import Users from '../pages/Users';
import SignUp from '../pages/SignUp';
import Rooms from '../pages/Rooms';
import Birthdays from '../pages/Birthdays';
import Room from '../pages/Room';
import Products from '../pages/Products';
import Post from '../pages/Post';
import Posts from '../pages/Posts';
import Ranking from '../pages/Ranking';
import Action from '../pages/Actions';
import Rescues from '../pages/Rescues';
import Trunks from '../pages/Trunks';
import Interactions from '../pages/Interactions';

const Router = () => (
  <Routes>
    <Route exact path="*" element={<RouterWrapper />}>
      <Route exact path="*" element={<Login />} />
    </Route>
    <Route exact path="/login" element={<RouterWrapper />}>
      <Route exact path="/login" element={<Login />} />
    </Route>
    <Route exact path="/" element={<PrivateRoute />}>
      <Route exact path="/" element={<Home />} />
    </Route>
    <Route exact path="/usuarios" element={<PrivateRoute />}>
      <Route exact path="/usuarios" element={<Users />} />
    </Route>
    <Route exact path="/usuario/:id" element={<PrivateRoute />}>
      <Route exact path="/usuario/:id" element={<User />} />
    </Route>
    <Route exact path="/mural" element={<PrivateRoute />}>
      <Route exact path="/mural" element={<Posts />} />
    </Route>
    <Route exact path="/postagem/:id" element={<PrivateRoute />}>
      <Route exact path="/postagem/:id" element={<Post />} />
    </Route>
    <Route exact path="/nova-postagem" element={<PrivateRoute />}>
      <Route exact path="/nova-postagem" element={<Post newPost={true} />} />
    </Route>
    <Route exact path="/ranking" element={<PrivateRoute />}>
      <Route exact path="/ranking" element={<Ranking />} />
    </Route>
    <Route exact path="/acoes" element={<PrivateRoute />}>
      <Route exact path="/acoes" element={<Action />} />
    </Route>
    <Route exact path="/aniversarios" element={<PrivateRoute />}>
      <Route exact path="/aniversarios" element={<Birthdays />} />
    </Route>
    <Route exact path="/produtos" element={<PrivateRoute />}>
      <Route exact path="/produtos" element={<Products />} />
    </Route>
    <Route exact path="/relatorio" element={<PrivateRoute />}>
      <Route exact path="/relatorio" element={<Home />} />
    </Route>
    <Route exact path="/salas" element={<PrivateRoute />}>
      <Route exact path="/salas" element={<Rooms />} />
    </Route>
    <Route exact path="/sala/:id" element={<PrivateRoute />}>
      <Route exact path="/sala/:id" element={<Room />} />
    </Route>
    <Route exact path="/cadastros" element={<PrivateRoute />}>
      <Route exact path="/cadastros" element={<SignUp />} />
    </Route>
    <Route exact path="/resgates" element={<PrivateRoute />}>
      <Route exact path="/resgates" element={<Rescues />} />
    </Route>
    <Route exact path="/baus" element={<PrivateRoute />}>
      <Route exact path="/baus" element={<Trunks />} />
    </Route>
    <Route exact path="/interacoes" element={<PrivateRoute />}>
      <Route exact path="/interacoes" element={<Interactions />} />
    </Route>
    <Route exact path="/relatorios" element={<PrivateRoute />}>
      <Route exact path="/relatorios" element={<Home />} />
    </Route>
  </Routes>
);

export default Router;
