import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

import api from '../../services/api';
import { Row } from './styles';
import { Container, Cards, Card, Title, ViewInput} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import photoUser from '../../images/user.png';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination, Select } from 'antd';
import { GoTasklist } from "react-icons/go";
import { format } from 'date-fns';

const { TextArea } = Input;

const Rescues = () => {
  const [rescues, setRescues] = useState([]);
  const [rescuesTotal, setRescuesTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [accept, setAccept] = useState(false)
  const [observation, setObservation] = useState("")
  const [page, setPage] = useState(0)
  const [room, setRoom] = useState("")
  const [action, setAction] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  useEffect(() => {
    loadRescuesPending()
  }, [page]);


  async function loadRescuesPending() {
   await api.get(`/rescues-pending?page=${page}`).then((response) => {
     setRescues(response.data.rescues)
     setRescuesTotal(response.data.rescuesTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function responseAction() {
    setIsLoadingModal(true)
    if (!accept) {
      if (!observation){
        toast.warning("Deixe uma mensagem para o usuário");
        setIsLoadingModal(false)
        return ""
      } 
    }

      await api.put(`/status-rescue/${action.id}`, {
        status: accept ? "aprovado" : "reprovado",
        observation: observation,
      }).then((response) => {
        toast.success( accept ? "Ação aprovada com sucesso" : "Ação reprovada com sucesso")
        setIsOpen(false)
        loadRescuesPending()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    setIsLoadingModal(false)
  }


  return (
    <Container>
      <Title>
        <h2>Resgates Pendentes</h2>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <GoTasklist color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={rescuesTotal} />
            <p>Total de Resgates</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {rescues.length ? (
              <>
                <Row>
                    {
                      rescues.map(action => (
                        <div key={action.id} className='user'>
                        <p>Solicitado {format(new Date(action.created_at), "dd/MM/yyyy HH:mm")}</p>
                          <row style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <img src={action.user.photo_url || photoUser} />
                            <div style={{flex: 1, marginLeft: 20}}>
                              <p>{action.user.name}</p>
                              <p>{action.user.email}</p>
                            </div>
                          </row>
                          <p>{action.product.name}</p>
                          {!!action.product.description && (<p>{action.product.description}</p>)}
                          <h4>{action.amount}x Total de {action.value} pontos</h4>
                          
                            <Button
                              type="primary"
                              onClick={() => {
                                setAccept(true)
                                setAction(action)
                                setObservation("")
                                setRoom("")
                                setIsOpen(true)
                              }}
                            >
                              Ver Solicitação
                            </Button>
                        </div>
                      ))
                    }
                  </Row>
                  <center style={{marginTop: 15}}>
                    <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                      setPage(page - 1)
                   }} total={rescuesTotal} pageSize={30} 
                   showTotal={(total) => `${total} resgates`}/>
                  </center>
                </>
              )
              : (
                <div className="error">
                  <p>Nenhum resgate encontrado</p>
                </div>
              )}
        </>
      )}

      {isLoading && <Loader />}
      <Modal title={"Responder Ação"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={"ENVIAR RESPOSTA"} cancelText="FECHAR" onOk={() => { 
          responseAction()
      }} onCancel={() => {
        setIsOpen(false)
        }}>
        {action && (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: 25 }}>
          <h4>Usuário</h4>
            <row style={{display: "flex", alignItems: "center", marginBottom: 15}}>
              <img style={{width: 50, height: 50, borderRadius: 50}} src={action.user.photo_url || photoUser} />
            <div style={{flexDirection: "column", marginLeft: 25}} >
              <p>Nome: {action.user.name}</p>
                          <p>Email: {action.user.email}</p>
                          <p>Telefone: {action.user.phone_number ? action.user.phone_number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : "Não cadastrado"}</p>
                          {!!action.user.observation && (<p>Observação: {action.user.observation}</p>)}
              </div>     
                          
                        </row>
                
          <h4>Ação</h4>

          <p>{action.name}</p>
          {!!action.description && (<p>{action.description}</p>)}
          <h4>{action.reward} pontos</h4>
          <h4>Observação do usuário</h4>
            <row style={{ display: "flex", justifyContent: "space-between", marginBottom: 15 }}>
              {!!action.photo_url && (
                <div style={{width: "48%", marginBottom: 10}}>
                  <p>Imagem: </p>
                  <img src={action.photo_url} style={{width: "100%"}} />
                </div>
              )}
              {!!action.observation && (<p style={{ width: "48%" }}>Observação: {action.observation}</p>)}
          </row>
         
            
          <ViewInput>
            <p>"Mensagem para o Usuário*</p>
            <TextArea
              placeholder="escreva aqui"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={observation}
              onChange={(event) => setObservation(event.target.value)} />
          </ViewInput>
                          <row style={{width: "100%", display: "flex", marginTop: 10, justifyContent: "space-between"}}>
                            <Button
                              type="primary"
                              style={{ background: accept ? "#2c2" : "#999", width: "48%" }}
                              onClick={() => {
                                setAccept(true)
                              }}
                            >
                              Aprovar
                            </Button>
                            <Button
                              type="primary"
                              style={{ background: !accept ? "#c22" : "#999", width: "48%" }}
                              onClick={() => {
                                setAccept(false)
                              }}
                            >
                              Reprovar
                            </Button>
                          </row>
          </div>
        )}
      </Modal>
    </Container>
  );
};

export default Rescues;
