import React, { useEffect, useState } from 'react';

import {
  MdHome, MdGroups, MdDashboard, MdFeed,
  MdCake, MdStore, MdRedeem, MdAnalytics,
  MdExitToApp
} from "react-icons/md";
import { FaRankingStar, FaNetworkWired } from "react-icons/fa6";

import { useLocation, useNavigate } from 'react-router-dom';

import { BsBoxes } from "react-icons/bs";
import { GoTasklist } from "react-icons/go";

import { Menu } from 'antd';
import "./style.css"
import { useAuth } from '../../hooks/AuthContext';

const MenuOptions = () => {

  const navigate = useNavigate();
  const local = useLocation();

  const { signOut } = useAuth();

  const [route, setRoute] = useState("")

  useEffect(() => {
    options.map((item, index) => {
      if (item.route == local.pathname) {
       setRoute(`${index}`)
     }
    })
  }, [local])

  const options = [
    {
      name: "Home",
      route: "/",
      icon: (<MdDashboard size={20} />)
    },
    {
      name: "Usuários",
      route: "/usuarios",
      icon: (<MdGroups size={20} />)
    },
    {
      name: "Cadastros",
      route: "/cadastros",
      icon: (<GoTasklist size={20} />)
    },
    {
      name: "Salas",
      route: "/salas",
      icon: (<MdHome size={20} />)
    },
    {
      name: "Baús",
      route: "/baus",
      icon: (<BsBoxes size={20} />)
    },
    {
      name: "Produtos",
      route: "/produtos",
      icon: (<MdStore size={20} />)
    },
    {
      name: "Mural",
      route: "/mural",
      icon: (<MdFeed  size={20} />)
    },
    {
      name: "Ações",
      route: "/acoes",
      icon: (<GoTasklist size={20} />)
    },
    {
      name: "Resgates",
      route: "/resgates",
      icon: (<MdRedeem size={20} />)
    },
    {
      name: "Ranking",
      route: "/ranking",
      icon: (<FaRankingStar size={20} />)
    },
    {
      name: "Interações",
      route: "/interacoes",
      icon: (<FaNetworkWired size={20} />)
    },
    {
      name: "Aniversários",
      route: "/aniversarios",
      icon: (<MdCake size={20} />)
    },
    {
      name: "Relatórios",
      route: "/relatorios",
      icon: (<MdAnalytics size={20} />)
    },
    {
      name: "Sair",
      route: "/login",
      icon: (<MdExitToApp size={20} />)
    }
  ]
  
  return (
    <Menu mode="inline" className="menu" selectedKeys={[route]}>
      {
        options.map((item, index) => {
          return (
            <Menu.Item key={index} onClick={(changeSelectedKey) => {
              setRoute(changeSelectedKey.key)
              if (item.route == "/login") {
                signOut()
              }
              navigate(item.route)
            }}
              className="option"
              type="primary"
              icon={item.icon}
            >
              {item.name}
            </Menu.Item>
          )
        })
      }
    </Menu>
  );
};

export default MenuOptions;
