import React, { useEffect, useState } from 'react';
import {
  MdAddPhotoAlternate,
  MdArrowBack
} from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import api from '../../services/api';
import { Container, ViewInput } from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Input } from 'antd';

const { TextArea } = Input;

const Post = ({newPost}) => {

  const { id } = useParams()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState("")
  const [text, setText] = useState("")
  const [photo, setPhoto] = useState("")
  const [isLoadingAction, setIsLoadingAction] = useState(false)


  useEffect(() => {
    if (!newPost) {
      loadPost()
    } else {
      setIsLoading(false);
    }
  }, []);

  async function loadPost() {
    await api.get(`/post/${id}`).then((response) => {
     setTitle(response.data.title)
     setText(response.data.text)
      setPhoto({ photo_url: response.data.photo_url })
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function createPost() {
    setIsLoadingAction(true)
    if (!title || !text || !photo){
      toast.warning("Titulo, Texto e Imagem são obrigatórios");
    } else {
      
      const data = new FormData();
      data.append('title', title);
      data.append('text', text);
      if (photo.name) {
        data.append('file', photo, photo.name);
      }
      await api.post(`/post`, data).then(() => {
        toast.success("Postagem criada com sucesso")
        navigate("/mural")
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingAction(false)
  }

  async function editPost() {
    setIsLoadingAction(true)
    if (!title || !text) {
      toast.warning("Titulo e Texto são obrigatórios");
    } else {
      
      const data = new FormData();
      data.append('title', title);
      data.append('text', text);
      if (photo.name) {
        data.append('file', photo, photo.name);
      }
      await api.put(`/post/${id}`, data).then(() => {
        toast.success("Postagem editada com sucesso")
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingAction(false)
  }

  return (
    <Container>

      <center>
        {isLoading ? <Loader /> : (
          <div style={{display: "flex", flexDirection: "column", width: "100%", maxWidth: 800, marginBottom: 25}}>
            <MdArrowBack size={25} style={{cursor: "pointer"}} onClick={()=>{navigate(-1)}}/>
            <div style={{ marginTop: 20, display: "flex", width: "100%", alignItems: "end", height: 200, borderRadius: 10, marginBottom: 25, justifyContent: "center" }}>
                <img style={{  width: "100%", maxWidth: 800, marginLeft: 50, height: 350, borderRadius: 10, background: "#fff", }} src={photo.photo_url ? photo.photo_url : "https://upload.wikimedia.org/wikipedia/commons/1/14/Product_sample_icon_picture.png"} />
                <button style={{
                  width: 50, height: 50, border: "none",
                  background: "transparent"
                }}>
                  <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                    <MdAddPhotoAlternate color="#001B22" size={25} />
                    <input id="avatar" style={{ display: "none" }} type="file" accept="image/png,image/jpeg"   onChange={(file) => {
                      let photo = file.target.files[0]
                      photo.photo_url = URL.createObjectURL(file.target.files[0])
                      setPhoto(photo)
                    }
                    }
                    />
                  </label>
                </button>
              </div>
              <ViewInput>
                <p>Titulo da Publicação*</p>
                <TextArea
                  placeholder="titulo"
                  style={{
                      minWidth: "100%",
                      minHeight: 50,
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: 7
                  }}
                  value={title}
                  onChange={(event) => setTitle(event.target.value)} />
              </ViewInput>
              <ViewInput style={{marginTop: 10}}>
                <p>Texto da Publicação*</p>
                <TextArea
                  placeholder="escreva aqui"
                  style={{
                      minWidth: "100%",
                      minHeight: 250,
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: 7
                  }}
                  value={text}
                  onChange={(event) => setText(event.target.value)} />
              </ViewInput>
            <Button type="primary" loading={isLoadingAction} style={{ marginTop: 25 }} onClick={() => {
                if (newPost) {
                  createPost()
                } else {
                  editPost()
                }
              }}>
                Salvar
              </Button>
            </div>
          )}
      </center>
    </Container>
  );
};

export default Post;
