import React, { useEffect, useState } from 'react';
import {
  MdHome,
  MdAdd,
  MdVisibility,
  MdEdit,
  MdDelete
} from 'react-icons/md';
import CountUp from 'react-countup';

import api from '../../services/api';
import { Container, Cards, Card, Title, ViewInput, Table, Scroll } from './../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination, Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;

const { TextArea } = Input;

const Rooms = () => {

  const navigate = useNavigate()

  const [rooms, setRooms] = useState([]);
  const [roomsTotal, setRoomsTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("")
  const [observation, setObservation] = useState("")
  const [page, setPage] = useState(0)
  const [is_social, setIs_social] = useState(false)
  const [room, setRoom] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  useEffect(() => {
    loadRooms()
  }, [page]);

  async function loadRooms() {
    setIsLoading(true)
   await api.get(`/rooms?page=${page}`).then((response) => {
     setRooms(response.data.rooms)
     setRoomsTotal(response.data.roomsTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function createRoom() {
    setIsLoadingModal(true)
    if (!name){
      toast.warning("Nome é obrigatório");
    } else {
      await api.post(`/room`, {
        name: name,
        is_social: is_social,
        observation: observation
      }).then((response) => {
        toast.success("Sala criada com sucesso")
        setIsOpen(false)
        loadRooms()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editRoom() {
    setIsLoadingModal(true)
    if (!name){
      toast.warning("Nome é obrigatório");
    } else {
      await api.put(`/room/${room.id}`, {
        name: name,
        is_social: is_social,
        observation: observation
      }).then((response) => {
        toast.success("Sala editada com sucesso")
        setIsOpen(false)
        loadRooms()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteRoom(room) {
    await api.delete(`/room/${room.id}`).then(response => {
      toast.success("Sala deletada com sucesso")
      loadRooms()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  return (
    <Container>
      <Title>
        <h2>Salas</h2>
        <div>
            <Button type="primary" onClick={() => {
              setRoom("")
              setName("")
              setObservation("")
              setIs_social(false)
              setIsOpen(true)
            }}>
            <MdAdd size="20" color="#fff" />
            <span>Nova Sala</span>
          </Button>
        </div>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <MdHome color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={roomsTotal} />
            <p>Total de Salas</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {rooms.length ? (
            <>
              <Scroll>
              <Table>
                <thead>
                  <tr>
                    <td>Sala</td>
                    <td>Usuários</td>
                    <td>Ações disponiveis</td>
                    <td>Interações disponiveis</td>
                    <td>Projeto Social</td>
                    <td style={{ width: 40 }}>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {
                    rooms.map(room => (
                      <tr key={room.id}>
                        <td>{room.name}</td>
                        <td>{room.users.length} Usuários</td>
                        <td>{room.room_actions.length} Ações</td>
                        <td>{room.room_interactions.length} Interações</td>
                        <td>{room.is_social ? "Sim" : "Não"}</td>
                        <td style={{width: 120}}>
                          <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", height: "100%" }}>
                            <MdVisibility
                              onClick={() => {
                                navigate(`/sala/${room.id}`)
                              }}
                              size={22}
                              color={"#001B22"}
                            />
                            <MdEdit
                              onClick={() => {
                                setRoom(room)
                                setName(room.name)
                                setIs_social(room.is_social)
                                setObservation(room.observation)
                                setIsOpen(true)
                              }}
                              style={{ marginLeft: 10 }}
                              size={22}
                              color={"#001B22"}
                            />
                            <MdDelete
                              onClick={() => {
                                confirm({
                                  title: 'Deseja excluir a Sala?',
                                  icon: <ExclamationCircleFilled/>,
                                  content: `Após essa ação, a Sala ${room.name} será excluida.`,
                                  onOk() {
                                    deleteRoom(room);
                                  },
                                  onCancel() {},
                                    cancelText: "Cancelar",
                                });
                              }}
                              style={{ marginLeft: 7 }}
                              size={22}
                              color={"#001B22"}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
                </Table>
              </Scroll>
              <center style={{marginTop: 15}}>
                <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                  setPage(page - 1)
               }} total={roomsTotal} pageSize={30} 
               showTotal={(total) => `${total} salas`}/>
              </center>
            </>
          ) : (
            <div className="error">
              <p>Nenhua sala encontrada</p>
            </div>
          )}
        </>
      )}

      {isLoading && <Loader />}
      <Modal title={room.id ? "Editar Sala" : "Nova Sala"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={room.id ? "EDITAR SALA" : "CADASTRAR SALA"} cancelText="FECHAR" onOk={() => { 
        if (room.id) {
          editRoom()
        } else {
          createRoom()
         }
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: 25 }}>   
          <ViewInput>
            <p>Nome da Sala*</p>
            <Input
              placeholder="nome"
              value={name}
              onChange={(event) => setName(event.target.value)} />
          </ViewInput>

          <ViewInput style={{width: "48%", textAlign: "left"}}>
            <p>Sala de Projeto Social?</p>
            <Switch
              placeholder="quantidade"
              value={is_social}
              onChange={(value) => setIs_social(value)} />
            </ViewInput>
          <ViewInput>
            <p>Observação</p>
            <TextArea
              placeholder="deixe uma observação"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={observation}
              onChange={(event) => setObservation(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
    </Container>
  );
};

export default Rooms;
