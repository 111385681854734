import React from 'react';

import { Container } from './styles';
import loader from '../../images/loading.gif';
import loaderWhite from '../../images/loadingWhite.gif';

const Loader = ({white, size}) => (
  <Container>
    <div>
      <img src={white ? loaderWhite : loader} alt="loader" style={size ? {width: size, height: size}: {}}/>
    </div>
  </Container>
);

export default Loader;
