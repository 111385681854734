import styled from 'styled-components';

export const Row = styled.table`
  width: 100%;
  margin-top: 25px;
  border: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #001B22;

  .user{
    width: 100%;
    max-width: 250px;
    display: flex;
    margin: 0 15px 15px 0;
    padding: 15px;
    border-radius: 10px;;
    flex-direction: column;
    align-items: center;
    background: #FFF;

    @media (max-width: 570px){
      max-width: 100%;
      margin: 0 0 15px 0;
    }

    img{
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-bottom: 10px;
    }

    p{
      width: 100%;
      text-align: left;
      margin: 5px 0;
    }
  }

  button {
    border: none; 
    width: 48%;
    color: #fff;
    border-radius: 5px;
    margin-top: 10px;
  }

`;
