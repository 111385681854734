import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@bomstar-dash:token');
    const user = localStorage.getItem('@bomstar-dash:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  /**
   * SignIn
   */
  const update = useCallback(({ user }) => {
      localStorage.setItem('@bomstar-dash:user', JSON.stringify(user));
      setData({ token: data.token, user: user });
  }, []);

  const signIn = useCallback(async ({ user, token}) => {

      localStorage.setItem('@bomstar-dash:token', token);
      localStorage.setItem('@bomstar-dash:user', JSON.stringify(user));

      api.defaults.headers = {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*"
      }
      
      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({ token, user });
      
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@bomstar-dash:user');
    localStorage.removeItem('@bomstar-dash:token');

    setData({});
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, update }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const contenxt = useContext(AuthContext);
  if (!contenxt) {
    throw new Error('useAuth must be used with an AuthProvider');
  }
  return contenxt;
}

export { AuthProvider, useAuth };
