import React, { useEffect, useState } from 'react';
import {
 MdAdd, MdVisibility,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';

import {
  BsBoxes, BsBoxSeam
} from 'react-icons/bs';

import {
  FaBoxOpen
} from 'react-icons/fa';

import api from '../../services/api';
import { Container, Cards, Card, Title, ViewInput, Table, Scroll} from '../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination, Select, Divider, Row} from 'antd';
import { format } from 'date-fns';

const { TextArea } = Input;

const Trunks = () => {

  const [users, setUsers] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [usersSelect, setUsersSelect] = useState([]);
  const [type, setType] = useState("all");
  const [trunks, setTrunks] = useState([]);
  const [trunksTotal, setTrunksTotal] = useState(0);
  const [trunksOpenTotal, setTrunksOpenTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("")
  const [mode, setMode] = useState("user")
  const [rewards, setRewards] = useState("")
  const [description, setDescription] = useState("")
  const [page, setPage] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  useEffect(() => {
    loadUsers()
  }, []);

  useEffect(() => {
    loadTrunks()
  }, [page, type]);

  async function loadTrunks() {
    await api.get(`/trunks-admin?page=${page}&type=${type}`).then((response) => {
     setTrunks(response.data.trunks)
     setTrunksTotal(response.data.trunksTotal)
     setTrunksOpenTotal(response.data.trunksOpenTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  async function loadUsers() {
    await api.get(`/users?all=true`).then((response) => {
     let users = response.data.users
     users.map((item)=>{
        item.value = item.id
        item.label = item.name
     })
     setUsers(users)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })

    await api.get(`/rooms?all=true`).then((response) => {
      let rooms = response.data.rooms
      rooms.map((item)=>{
         item.value = item.id
         item.label = item.name
      })
      setRooms(rooms)
     }).catch(({ response }) => {
       if (response) {
         if (response.data) {
           if (response.data.message) {
             toast.warn(response.data.message)
           } else {
             toast.error("Erro Interno. verifique sua conexão e tente novamente")
           }
         } else {
           toast.error("Erro Interno. verifique sua conexão e tente novamente")
         }
       } else {
         toast.error("Erro Interno. verifique sua conexão e tente novamente")
       }
     })
   setIsLoading(false);
  }

  async function sendTrunks() {
    setIsLoadingModal(true)
    let arrayRewards = rewards.replace(/^,|,$/g, '').split(",")
    if (!name || !arrayRewards.length || !usersSelect.length) {
      toast.warning("Nome, Premiação e Recebedores são obrigatórios");
    } else {
      if (arrayRewards.length < 3) {
        toast.warning("Insira no mínimo três premiações para avançar");
        setIsLoadingModal(false)
        return ""
      }
      await api.post(`/trunks`, {
        name: name,
        mode: mode,
        rewards: rewards.replace(/^,|,$/g, ''),
        description: description,
        users: usersSelect,
      }).then((response) => {
        toast.success("Baús enviados com sucesso")
        setIsOpen(false)
        loadTrunks()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  return (
    <Container>
      <Title>
        <h2>Baús</h2>
        <div>
            <Button type="primary" onClick={() => {
              setName("")
              setRewards("")
              setUsersSelect([])
              setDescription("")
              setIsOpen(true)
            }}>
            <MdAdd size="20" color="#fff" />
            <span>Novo envio de Baú</span>
          </Button>
        </div>
      </Title>
      <Cards>
        <Card style={{marginRight: 15, cursor: "pointer"}}
        onClick={()=>{
          setType("all")
          setPage(0)
        }}
        active={type == "all"}>
          <div className="icon">
            <BsBoxes size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={trunksTotal} />
            <p>Total de baús</p>
          </div>
        </Card>
        <Card style={{marginRight: 15, cursor: "pointer"}}
        onClick={()=>{
          setType("open")
          setPage(0)
        }}
        active={type == "open"}>
          <div className="icon">
            <FaBoxOpen size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={trunksOpenTotal} />
            <p>Total de baús abertos</p>
          </div>
        </Card>
        <Card style={{ cursor: "pointer"}}
        onClick={()=>{
          setType("close")
          setPage(0)
        }}
        active={type == "close"}>
          <div className="icon">
            <BsBoxSeam size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={trunksTotal-trunksOpenTotal} />
            <p>Total de baús pendentes</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {trunks.length ? (
              <>
              <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td style={{ width: 50 }}>
                      </td>
                      <td>Recebedor</td>
                      <td>Baú</td>
                      <td>Premiação</td>
                      <td style={{minWidth: 150}}>Enviado</td>
                      <td style={{minWidth: 100}}>Aberto</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      trunks.map(trunk => (
                        <tr key={trunk.id}>
                          <td style={{ width: 40 }}>
                            <img style={{ width: 30, height: 30, borderRadius: 2}} src={trunk.user.photo_url || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                          </td>
                          <td>{trunk.user.name}</td>
                          <td>{trunk.name}</td>
                          <td>{trunk.open ? `${trunk.reward} pontos`: "Não Aberto"}</td>
                          <td>{format(trunk.created_at, "dd/MM/yyyy HH:mm")}</td>
                          <td>{trunk.open ? format(trunk.updated_at, "dd/MM/yyyy HH:mm") : "Não Aberto"}</td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </Table>
                </Scroll>
                  <center style={{marginTop: 15}}>
                    <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                      setPage(page - 1)
                   }} total={type == "all" ? trunksTotal : type == "open" ? trunksOpenTotal : trunksTotal-trunksOpenTotal} pageSize={30} 
                   showTotal={(total) => `${total} baús`}/>
                  </center>
                </>
              )
              : (
                <div className="error">
                  <p>Nenhum baú encontrado</p>
                </div>
              )}
        </>
      )}

      {isLoading && <Loader />}
      <Modal title={"Envio de Baús"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={"ENVIAR BAÚ"} cancelText="FECHAR" onOk={() => { 
         sendTrunks()
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
          <ViewInput>
            <p>Nome do Baú*</p>
            <Input
              placeholder="nome"
              value={name}
              onChange={(event) => setName(event.target.value)} />
          </ViewInput>

          <ViewInput>
              <p>Modo de Envio</p>
              <Row>
                <Button
                  style={{background: mode == "user" ? "#B40000" : "#FFF", width: "48%", color: mode == "user" ? "#fff" : "#000"}}
                  onClick={()=>{
                    if(mode != "user"){
                      setUsersSelect([])
                    }
                    setMode("user")
                    
                  }}
                >
                  Por Usuário
                </Button>
                <Button
                  style={{background: mode == "room" ? "#B40000" : "#FFF", width: "48%", marginLeft: "4%", color: mode == "room" ? "#fff" : "#000"}}
                  onClick={()=>{
                    if(mode != "room"){
                      setUsersSelect([])
                    }
                    setMode("room")
                  }}
                >
                  Por Sala
                </Button>
              </Row>
          </ViewInput>
          <ViewInput>
              <p>Quem receberá o baú</p>
              <Select
                mode="multiple"
                showSearch
                value={usersSelect}
                style={{
                  width: '100%', textAlign: "left", fontSize: 13}}
                onChange={(value) => {
                  setUsersSelect(value)
                }}
                optionFilterProp="label"
                allowClear
                placeholder="Selecione pelo menos uma pessoa ou sala"
                options={mode == "user" ? users : rooms}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider />
                      <Button
                        style={{background: "#B40000", width: "100%", color: "#fff"}}
                        onClick={()=>{
                          let selects = []
                          if(mode == "user"){
                            users.map((item)=>{
                              selects.push(item.value)
                            })
                          }else{
                            rooms.map((item)=>{
                              selects.push(item.value)
                            })
                          }
                          setUsersSelect(selects)
                        }}
                      >
                      Selecionar Todos
                    </Button>
                  </>
                )}
              />
            </ViewInput>
          <ViewInput>
              <p>Possiveis premiações separados por ( , )</p>
            <TextArea style={{ width: '100%' }}
                value={rewards} 
                autoSize={{ maxRows: 5}}
                onChange={(event) => setRewards(event.target.value.replace(/[^\d,]|(,)+/g, (match, grupo1) => grupo1 ? ',' : ''))} />
          </ViewInput>
          <ViewInput>
            <p>Descrição</p>
            <TextArea
              placeholder="deixe uma descrição"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={description}
              onChange={(event) => setDescription(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
    </Container>
  );
};

export default Trunks;
