import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Cards, Container, Table, Title, ViewInput, InputTel } from './../../GlobalStyles';
import Loader from '../../components/Loader';
import { MdAdd, MdAddPhotoAlternate, MdCheckCircle, MdDelete, MdEdit, MdExitToApp, MdGroups, MdVisibility } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination, DatePicker, Select, Divider } from 'antd';
import api from '../../services/api';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { FaNetworkWired } from "react-icons/fa6";

import CountUp from 'react-countup';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/pt_BR';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)

const { TextArea } = Input;

const { confirm } = Modal;

const Room = () => {
  
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [usersSelect, setUsersSelect] = useState([]);
  const [select, setSelect] = useState("usuario");
  const [type, setType] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [name, setName] = useState("")
  const [observation, setObservation] = useState("")
  const [email, setEmail] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [password, setPassword] = useState("")
  const [description, setDescription] = useState("")
  const [reward, setReward] = useState("")
  const [idAction, setIdAction] = useState("")
  const [photo, setPhoto] = useState("")
  const [value, setValue] = useState("")
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenAction, setIsOpenAction] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [isOpenInteraction, setIsOpenInteraction] = useState(false)
  const [room, setRoom] = useState({});

  async function loadRoom() {
    await api.get(`/room/${id}`).then((response) => {
      setRoom(response.data)
      let users = []
      response.data.users.map((item)=>{
        users.push({
          value: item.id,
          label: item.name
        })
      })
      setUsers(users)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadRoom()
  }, []);

 
  async function createUser() {
    setIsLoadingModal(true)
    if (!name || !email || !phone_number || !birthday || !password || !room){
      toast.warning("Nome, Email, Telefone, Aniversário, Sala e Senha são obrigatórios");
    } else {
      if (phone_number) {
        if (phone_number.length != 11) {
          toast.warn("Telefone inválido")
          setIsLoadingModal(false)
          return ""
        }
      }
      
      const data = new FormData();
      data.append('name', name);
      data.append('email', email);
      data.append('birthday', birthday);
      data.append('password', password);
      data.append('room_id', room.id);
      data.append('observation', observation);
      data.append('phone_number', phone_number);
      if (photo.name) {
        data.append('file', photo, photo.name);
      }
      await api.post(`/create-user`, data).then((response) => {
        toast.success("Usuário criado com sucesso")
        setIsOpen(false)
        loadRoom()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function createActionAndInteraction() {
    setIsLoadingModal(true)
    if (!name || !reward || !room){
      toast.warning("Nome, Recompensa e Sala são obrigatórios");
    } else {
      await api.post(`/${select}-room`, {
        name: name,
        reward: reward,
        description: description,
        room_id: room.id,
      }).then((response) => {
        toast.success("Ação criada com sucesso")
        setIsOpenAction(false)
        loadRoom()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editActionAndInteraction() {
    setIsLoadingModal(true)
    if (!name || !reward){
      toast.warning("Nome e Recompensa são obrigatórios");
    } else {
      await api.put(`/${select}-room/${idAction}`, {
        name: name,
        reward: reward,
        description: description,
      }).then((response) => {
        toast.success("Ação editada com sucesso")
        setIsOpenAction(false)
        loadRoom()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteActionAndInteraction(action) {
    await api.delete(`/${select}-room/${action.id}`).then(response => {
      toast.success("Ação deletada com sucesso")
      loadRoom()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  async function createBonus() {
    setIsLoadingModal(true)
    if (!value || !description || !usersSelect.length){
      toast.warning("Descrição, Valor e no minimo um usuário são obrigatórios");
    } else {
      await api.post(`/bonus-room`, {
        name: "Bonificação",
        value: value,
        description: description,
        users: usersSelect,
      }).then((response) => {
        toast.success("Bonificação criada com sucesso")
        setIsOpenInteraction(false)
        loadRoom()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function createPenalty() {
    setIsLoadingModal(true)
    if (!value || !description || !usersSelect.length){
      toast.warning("Descrição, Valor e no minimo um usuário são obrigatórios");
    } else {
      await api.post(`/penalty-room`, {
        name: "Penalização",
        value: value,
        description: description,
        users: usersSelect,
      }).then((response) => {
        toast.success("Penalização criada com sucesso")
        setIsOpenInteraction(false)
        loadRoom()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Title>
            <h2>Sala {room.name}</h2>
            <div>
              <Button type="primary" onClick={() => {
                  setType("")
                  setValue("")
                  setDescription("")
                  setUsersSelect([])
                  setIsOpenInteraction(true)
              }}>
                <MdAdd size="20" color="#fff" />
                <span>Penalização e Bonificação</span>
              </Button>
              <Button type="primary" onClick={() => {
                setName("")
                setPhone_number("")
                setEmail("")
                setBirthday(null)
                setPassword("")
                setPhoto("")
                setObservation("")
                setIsOpen(true)
              }}>
                <MdAdd size="20" color="#fff" />
                <span>Novo Usuário</span>
              </Button>
              <Button type="primary" onClick={() => {
                setName("")
                setDescription("")
                setReward("")
                setIdAction("")
                setSelect("action")
                setIsOpenAction(true)
              }}>
                <MdAdd size="20" color="#fff" />
                <span>Nova Ação</span>
              </Button>
              <Button type="primary" onClick={() => {
                setName("")
                setDescription("")
                setReward("")
                setIdAction("")
                setSelect("interaction")
                setIsOpenAction(true)
              }}>
                <MdAdd size="20" color="#fff" />
                <span>Nova Interação</span>
              </Button>
            </div>
            </Title>
            <row style={{display: "flex", width: "100%", margin: "40px 0"}}>
              <Button type="primary" style={{ flex: 1, height: 40, borderRadius: 5, marginRight: 5, background: select == "usuario" ? "#CFBE06" : "#999" }} onClick={() => {
                setSelect("usuario")
              }}>
                <span>Usuários</span>
              </Button>
              <Button type="primary" style={{ flex: 1, height: 40, borderRadius: 5, marginLeft: 5, background: select == "action" ? "#CFBE06" : "#999" }} onClick={() => {
                setSelect("action")
              }}>
                <span>Ações</span>
              </Button>
              <Button type="primary" style={{ flex: 1, height: 40, borderRadius: 5, marginLeft: 5, background: select == "interaction" ? "#CFBE06" : "#999" }} onClick={() => {
                setSelect("interaction")
              }}>
                <span>Interações</span>
              </Button>
            </row>
            {
              select == "usuario" && (
                <>
                  {
                    room.users.length ? (
                      <>
                        <Cards>
                          <Card>
                            <div className="icon">
                              <MdGroups color="#848484" size={32} />
                            </div>
                            <div className="number">
                              <CountUp duration={1} end={room.users.length} />
                              <p>Total de Usuário desta sala</p>
                            </div>
                          </Card>
                        </Cards>
                        <Table>
                          <thead>
                            <tr>
                              <td style={{ width: 50 }}>
                              </td>
                              <td>Nome</td>
                              <td>Email</td>
                              <td style={{ minWidth: 150 }}>Telefone</td>
                              <td style={{ minWidth: 100 }}>Pontos</td>
                              <td style={{ minWidth: 100 }}>Interação</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              room.users.map(user => (
                                <tr key={user.id}>
                                  <td style={{ width: 40 }}>
                                    <img style={{ width: 30, height: 30, borderRadius: 2 }} src={user.photo_url || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                                  </td>
                                  <td>{user.name}</td>
                                  <td>{user.email}</td>
                                  <td>{user.phone_number ? user.phone_number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : "Não cadastrado"}</td>
                                  <td>{user.points} Pontos</td>
                                  <td>{user.points_interactions} Pontos</td>
                                  <td style={{ width: 120 }}>
                                    <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                              
                                      <MdVisibility
                                        onClick={() => {
                                          navigate(`/usuario/${user.id}`)
                                        }}
                                        style={{ cursor: "pointer" }}
                                        size={22}
                                        color={"#001B22"}
                                      />
                                      <MdExitToApp
                                        onClick={() => {
                                          confirm({
                                            title: 'Deseja mover o Usuário para outra sala?',
                                            icon: <ExclamationCircleFilled />,
                                            content: `Após essa ação, o Usuário ${user.name} será movido.`,
                                            onOk() {
                                              // deleteUser(user);
                                            },
                                            onCancel() { },
                                            cancelText: "Cancelar",
                                          });
                                        }}
                                        style={{ cursor: "pointer", marginLeft: 6 }}
                                        size={22}
                                        color={"#001B22"}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                        <center style={{ marginTop: 15 }}>
                          <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                            setPage(page - 1)
                          }} total={room.users.length} pageSize={30}
                            showTotal={(total) => `${total} ações`} />
                        </center>
                      </>
                    )
                      : (
                        <div className="error">
                          <p>Nenhuma usuário encontrado</p>
                        </div>
                      )}
                </>
              )}
             {
              select == "action" &&(
                <> {
                  room.room_actions.length ? (
                    <>
                    <Cards>
                    <Card>
                      <div className="icon">
                        <MdCheckCircle color="#848484" size={32} />
                      </div>
                      <div className="number">
                        <CountUp duration={1} end={ room.room_actions.length } />
                        <p>Total de Ações desta sala</p>
                      </div>
                    </Card>
                  </Cards>
                    <Table>
                      <thead>
                        <tr>
                          <td>Nome</td>
                          <td>Descrição</td>
                          <td style={{minWidth: 100}}>Recompensa</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                          {
                          room.room_actions.map(action => (
                              <tr key={action.id}>
                                <td>{action.name}</td>
                                <td>{action.description || "Sem descrição"}</td>
                                <td>{action.reward} Pontos</td>
                                <td style={{width: 120}}>
                                    <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                                
                                      <MdEdit
                                    onClick={() => { 
                                        setName(action.name)
                                        setDescription(action.description)
                                        setReward(action.reward)
                                        setIdAction(action.id)
                                        setIsOpenAction(true)
                                      
                                        }}
                                        style={{ cursor: "pointer"}}
                                        size={22}
                                        color={"#001B22"}
                                      />
                                      <MdDelete
                                        onClick={() => {
                                          confirm({
                                            title: 'Deseja excluir a Ação?',
                                            icon: <ExclamationCircleFilled/>,
                                            content: `Após essa ação, a Ação ${action.name} será excluida.`,
                                            onOk() {
                                              deleteActionAndInteraction(action);
                                            },
                                            onCancel() {},
                                              cancelText: "Cancelar",
                                          });
                                        }}
                                          style={{ cursor: "pointer", marginLeft: 6 }}
                                          size={22}
                                          color={"#001B22"}
                                        />
                                        </div>
                                  </td>
                              </tr>
                            ))
                          }
                      </tbody>
                      </Table>
                      <center style={{marginTop: 15}}>
                        <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                          setPage(page - 1)
                       }} total={room.room_actions.length} pageSize={30} 
                       showTotal={(total) => `${total} ações`}/>
                      </center>
                    </>
                  )
                  : (
                    <div className="error">
                      <p>Nenhuma ação encontrada</p>
                    </div>
                  )}
                </>
              )
            }
            {
             select == "interaction" &&(
               <> {
                 room.room_interactions.length ? (
                   <>
                   <Cards>
                   <Card>
                     <div className="icon">
                       <FaNetworkWired color="#848484" size={32} />
                     </div>
                     <div className="number">
                       <CountUp duration={1} end={ room.room_interactions.length } />
                       <p>Total de Interações desta sala</p>
                     </div>
                   </Card>
                 </Cards>
                   <Table>
                     <thead>
                       <tr>
                         <td>Nome</td>
                         <td>Descrição</td>
                         <td style={{minWidth: 100}}>Recompensa</td>
                         <td></td>
                       </tr>
                     </thead>
                     <tbody>
                         {
                         room.room_interactions.map(action => (
                             <tr key={action.id}>
                               <td>{action.name}</td>
                               <td>{action.description || "Sem descrição"}</td>
                               <td>{action.reward} Pontos</td>
                               <td style={{width: 120}}>
                                   <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                               
                                     <MdEdit
                                   onClick={() => { 
                                       setName(action.name)
                                       setDescription(action.description)
                                       setReward(action.reward)
                                       setIdAction(action.id)
                                       setIsOpenAction(true)
                                     
                                       }}
                                       style={{ cursor: "pointer"}}
                                       size={22}
                                       color={"#001B22"}
                                     />
                                     <MdDelete
                                       onClick={() => {
                                         confirm({
                                           title: 'Deseja excluir a Interação?',
                                           icon: <ExclamationCircleFilled/>,
                                           content: `Após essa interação, a Interação ${action.name} será excluida.`,
                                           onOk() {
                                             deleteActionAndInteraction(action);
                                           },
                                           onCancel() {},
                                             cancelText: "Cancelar",
                                         });
                                       }}
                                         style={{ cursor: "pointer", marginLeft: 6 }}
                                         size={22}
                                         color={"#001B22"}
                                       />
                                       </div>
                                 </td>
                             </tr>
                           ))
                         }
                     </tbody>
                     </Table>
                     <center style={{marginTop: 15}}>
                       <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                         setPage(page - 1)
                      }} total={room.room_interactions.length} pageSize={30} 
                      showTotal={(total) => `${total} interações`}/>
                     </center>
                   </>
                 )
                 : (
                   <div className="error">
                     <p>Nenhuma interação encontrada</p>
                   </div>
                 )}
               </>
             )
           }
        </>
      )}
      <Modal title={"Novo Usuário"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={"CADASTRAR USUÁRIO"} cancelText="FECHAR" onOk={() => { 
          createUser()
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
        <div style={{ marginTop: 0, display: "flex", width: "100%", alignItems: "end", height: 120, borderRadius: 10, marginBottom: 25, marginBottom: 20, justifyContent: "center" }}>
            <img style={{ width: 120, marginLeft: 50, height: 120, borderRadius: 10, background: "#fff", }} src={photo.photo_url ? photo.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
            <button style={{
              width: 50, height: 50, border: "none",
              background: "transparent"
            }}>
              <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                <MdAddPhotoAlternate color="#001B22" size={25} />
                <input id="avatar" style={{ display: "none" }} type="file"accept="image/png,image/jpeg"   onChange={(file) => {
                  let photo = file.target.files[0]
                  photo.photo_url = URL.createObjectURL(file.target.files[0])
                  setPhoto(photo)
                }
                }
                />
              </label>
            </button>
          </div>
          <ViewInput>
            <p>Nome*</p>
            <Input
              placeholder="nome"
              value={name}
              onChange={(event) => setName(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Email*</p>
            <Input
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)} />
          </ViewInput>
          <ViewInput>
              <p>Senha*</p>
              <Input
                placeholder="Email"
                value={password}
                onChange={(event) => setPassword(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Telefone*</p>
            <InputTel mask="(99) 99999-9999" maskChar="" noSpaceBetweenChars={true}
              value={phone_number} placeholder={"telefone"}
              onChange={(text) => { setPhone_number(text.target.value.replace(/[^0-9]/g, '')) }} />
          </ViewInput>
          <ViewInput>
            <p>Data de Nascimento*</p>
            <DatePicker
              locale={locale}
                placeholder="data de nascimento"
                format={"DD/MM/YYYY"}
                maxDate={dayjs(new Date())}
                value={birthday ? dayjs(birthday) : ""}
                style={{
                    width: "100%",
                    height: 32,
                    display: 'flex',
                    alignItems: 'center',
                }}

                onChange={(date, d) => setBirthday(date)}
              />
          </ViewInput>
          <ViewInput>
            <p>Observação</p>
            <TextArea
              placeholder="deixe uma observação"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={observation}
              onChange={(event) => setObservation(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>

      <Modal title={idAction ? `Editar ${select == "action" ? "Ação" : "Interação"}` : `Nova ${select == "action" ? "Ação" : "Interação"}`} width={500}
        confirmLoading={isLoadingModal} open={isOpenAction}
        okText={idAction ? `EDITAR ${select == "action" ? "AÇÃO" : "INTERAÇÃO"}` : `CADASTRAR ${select == "action" ? "AÇÃO" : "INTERAÇÃO"}`} cancelText="FECHAR" onOk={() => { 
          if (idAction) {
            editActionAndInteraction()
          } else {
            createActionAndInteraction()
          }
      }} onCancel={() => {
        setIsOpenAction(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
          <ViewInput>
            <p>Nome*</p>
            <Input
              placeholder="nome"
              value={name}
              onChange={(event) => setName(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Pontos de Recompensa*</p>
            <Input
              type='number'
              placeholder="pontos de recompensa"
              value={reward}
              onChange={(event) => setReward(parseInt(event.target.value))} />
          </ViewInput>
          <ViewInput>
            <p>Descrição</p>
            <TextArea
              placeholder="deixe uma descrição"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={description}
              onChange={(event) => setDescription(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
      <Modal title={`Bonificação ou Penalidade`} width={500}
        confirmLoading={isLoadingModal} open={isOpenInteraction}
        okText={`ENVIAR ${type == "bonus" ? "BONIFICAÇÃO" : "PENALIZAÇÃO"}`} cancelText="FECHAR" onOk={() => { 
          if (type) {
            if (type == "bonus") {
              createBonus()
            } else {
              createPenalty()
            }
          } else {
            toast.warn("Selecione o tipo de interação com o usuário")
          }
          
      }} onCancel={() => {
        setIsOpenInteraction(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
          <ViewInput>
            <p>Tipo de Interação*</p>
            <Select
              placeholder="nome"
              style={{width: "100%", textAlign: "left"}}
              value={type || null}
              options={[{
                label: "Bonificação",
                value: "bonus"
              }, {
                label: "Penalização",
                value: "penalty"
              }]}
              onChange={(value) => setType(value)} />
          </ViewInput>
          <ViewInput>
              <p>Quem receberá o baú</p>
              <Select
                mode="multiple"
                showSearch
                value={usersSelect}
                style={{
                  width: '100%', textAlign: "left", fontSize: 13}}
                onChange={(value) => {
                  setUsersSelect(value)
                }}
                optionFilterProp="label"
                allowClear
                placeholder="Selecione pelo menos uma pessoa"
                options={users}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider />
                      <Button
                        style={{background: "#B40000", width: "100%", color: "#fff"}}
                        onClick={()=>{
                          let selects = []
                          users.map((item)=>{
                            selects.push(item.value)
                          })
                          setUsersSelect(selects)
                        }}
                      >
                      Selecionar Todos
                    </Button>
                  </>
                )}
              />
            </ViewInput>
          <ViewInput>
            <p>Pontos*</p>
            <Input
              type='number'
              placeholder="pontos"
              value={value}
              onChange={(event) => setValue(parseInt(event.target.value))} />
          </ViewInput>
          <ViewInput>
            <p>Descrição</p>
            <TextArea
              placeholder="deixe uma descrição"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={description}
              onChange={(event) => setDescription(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
    </Container>
  );
};

export default Room;
