import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Title} from './../../GlobalStyles';
import Loader from '../../components/Loader';
import api from '../../services/api';
import { Row } from './styles';
import CountUp from 'react-countup';

const Home = () => {

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true);
  const [home, setHome] = useState({});

  async function loadUser() {
    await api.get(`/home`).then((response) => {
      setHome(response.data)
    })
    setIsLoading(false)
  }

  useEffect(() => {
    loadUser()
  }, []);



  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Title>
              <h2>Home</h2>
            </Title>
            <Row>
              <button className='infos' onClick={() => {
                navigate("/usuarios")
              }}>
                <CountUp duration={2} end={home.usersTotal} />
                <p>Usuários ativos</p>
              </button>
              <button className='infos' onClick={() => {
                navigate("/salas")
              }}>
                <CountUp duration={2} end={home.roomsTotal} />
                <p>Salas ativas</p>
              </button>
              <button className='infos' onClick={() => {
                navigate("/produtos")
              }}>
                <CountUp duration={2} end={home.productsTotal} />
                <p>Produtos ativos</p>
              </button>
              <button className='infos' onClick={() => {
                navigate("/interacoes")
              }}>
                <CountUp duration={2} end={home.interactionsTotal} />
                <p>Interações</p>
              </button>
              <button className='infos' onClick={() => {
                navigate("/mural")
              }}>
                <CountUp duration={2} end={home.postsTotal} />
                <p>Postagens no Mural</p>
              </button>
              <button className='infos' onClick={() => {
                navigate("/cadastros")
              }}>
                <CountUp duration={2} end={home.usersPendingTotal} />
                <p>Cadastros pendentes</p>
              </button>
              <button className='infos' onClick={() => {
                navigate("/resgates")
              }}>
                <CountUp duration={2} end={home.rescuesTotal} />
                <p>Resgates pendentes</p>
              </button>
              <button className='infos' onClick={() => {
                navigate("/acoes")
              }}>
                <CountUp duration={2} end={home.actionsTotal} />
                <p>Ações pendentes</p>
              </button>
              <button className='infos' onClick={() => {
                navigate("/aniversarios")
              }}>
                <CountUp duration={2} end={home.birthdaysTotal} />
                <p>Aniversariantes nos próximos 30 dias</p>
              </button>
            </Row>
        </>
      )}
    </Container>
  );
};

export default Home;
