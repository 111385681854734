import React, { useEffect, useState } from 'react';
import {
 MdAdd, MdAddPhotoAlternate, MdDelete, MdEdit, MdGroups, MdVisibility,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import { ExclamationCircleFilled } from '@ant-design/icons';

import api from '../../services/api';
import { Container, Cards, Card, Title, ViewInput, InputTel, Table, Scroll} from './../../GlobalStyles';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { Button, Modal, Input, Pagination, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/pt_BR';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)

const { confirm } = Modal;

const { TextArea } = Input;

const Users = () => {

  const navigate = useNavigate()

  const [users, setUsers] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [usersTotal, setUsersTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [birthday, setBirthday] = useState(null);
  const [name, setName] = useState("")
  const [room, setRoom] = useState("")
  const [observation, setObservation] = useState("")
  const [email, setEmail] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [page, setPage] = useState(0)
  const [password, setPassword] = useState("")
  const [photo, setPhoto] = useState("")
  const [id, setId] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState(false)

  useEffect(() => {
    loadRooms()
  }, []);

  useEffect(() => {
    loadUsers()
  }, [page]);

  async function loadRooms() {
    await api.get(`/rooms?all=true`).then((response) => {
     let rooms = response.data.rooms
     rooms.map((item)=>{
        item.value = item.id
        item.label = item.name
     })
     setRooms(rooms)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  async function loadUsers() {
   await api.get(`/users?page=${page}`).then((response) => {
     setUsers(response.data.users)
     setUsersTotal(response.data.usersTotal)
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
   setIsLoading(false);
  }

  async function createUser() {
    setIsLoadingModal(true)
    if (!name || !email || !phone_number || !birthday || !password || !room){
      toast.warning("Nome, Email, Telefone, Aniversário, Sala e Senha são obrigatórios");
    } else {
      if (phone_number) {
        if (phone_number.length != 11) {
          toast.warn("Telefone inválido")
          setIsLoadingModal(false)
          return ""
        }
      }
      
      const data = new FormData();
      data.append('name', name);
      data.append('email', email);
      data.append('birthday', birthday);
      data.append('password', password);
      data.append('room_id', room);
      data.append('observation', observation);
      data.append('phone_number', phone_number);
      if (photo.name) {
        data.append('file', photo, photo.name);
      }
      await api.post(`/create-user`, data).then((response) => {
        toast.success("Usuário criado com sucesso")
        setIsOpen(false)
        loadUsers()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function editUser() {
    setIsLoadingModal(true)
    if (!name || !email || !phone_number || !birthday || !room){
      toast.warning("Nome, Email, Telefone, Aniversário e Sala são obrigatórios");
    } else {
      if (phone_number) {
        if (phone_number.length != 11) {
          toast.warn("Telefone inválido")
          setIsLoadingModal(false)
          return ""
        }
      }

      const data = new FormData();
      data.append('name', name);
      data.append('email', email);
      data.append('observation', observation);
      data.append('room_id', room);
      data.append('birthday', birthday);
      data.append('phone_number', phone_number);
      if (photo.name) {
        data.append('file', photo, photo.name);
      }
      await api.put(`/edit-user/${id}`, data).then((response) => {
        toast.success("Usuário editado com sucesso")
        setIsOpen(false)
        loadUsers()
      }).catch(({ response }) => {
        if (response) {
          if (response.data) {
            if (response.data.message) {
              toast.warn(response.data.message)
            } else {
              toast.error("Erro Interno. verifique sua conexão e tente novamente")
            }
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      })
    }
    setIsLoadingModal(false)
  }

  async function deleteUser(user) {
    await api.delete(`/user/${user.id}`).then(response => {
      toast.success("Usuário deletado com sucesso")
      loadUsers()
    }).catch(({ response }) => {
      if (response) {
        if (response.data) {
          if (response.data.message) {
            toast.warn(response.data.message)
          } else {
            toast.error("Erro Interno. verifique sua conexão e tente novamente")
          }
        } else {
          toast.error("Erro Interno. verifique sua conexão e tente novamente")
        }
      } else {
        toast.error("Erro Interno. verifique sua conexão e tente novamente")
      }
    })
  }

  return (
    <Container>
      <Title>
        <h2>Usuários</h2>
        <div>
            <Button type="primary" onClick={() => {
              setId("")
              setName("")
              setPhone_number("")
              setEmail("")
              setRoom("")
              setBirthday(null)
              setPassword("")
              setPhoto("")
              setObservation("")
              setIsOpen(true)
            }}>
            <MdAdd size="20" color="#fff" />
            <span>Novo Usuário</span>
          </Button>
        </div>
      </Title>
      <Cards>
        <Card>
          <div className="icon">
            <MdGroups color="#848484" size={32} />
          </div>
          <div className="number">
            <CountUp duration={1} end={usersTotal} />
            <p>Total</p>
          </div>
        </Card>
      </Cards>

      {!isLoading && (
        <>
          {users.length ? (
              <>
              <Scroll>
                <Table>
                  <thead>
                    <tr>
                      <td style={{ width: 50 }}>
                      </td>
                      <td>Nome</td>
                      <td>Email</td>
                      <td style={{minWidth: 120}}>Telefone</td>
                      <td style={{minWidth: 80}}>Pontos</td>
                      <td style={{minWidth: 80}}>Interação</td>
                      <td style={{ width: 80 }}>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      users.map(user => (
                        <tr key={user.id}>
                          <td style={{ width: 40 }}>
                            <img style={{ width: 30, height: 30, borderRadius: 2}} src={user.photo_url || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
                          </td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.phone_number ? user.phone_number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : "Não cadastrado"}</td>
                          <td>{user.points} Pontos</td>
                            <td>{user.points_interactions} Pontos</td>
                          
                        <td style={{width: 120}}>
                            <div style={{ display: "flex", cursor: "pointer", alignItems: "center", justifyContent: "center", }}>
                         
                              <MdVisibility
                                onClick={() => { 
                                  navigate(`/usuario/${user.id}`)
                                }}
                                style={{ cursor: "pointer"}}
                                size={22}
                                color={"#001B22"}
                              />
                            <MdEdit
                              onClick={() => {
                                setId(user.id)
                                setName(user.name)
                                setPhone_number(user.phone_number)
                                setObservation(user.observation)
                                setRoom(user.room_id)
                                setEmail(user.email)
                                setBirthday(user.birthday)
                                setPhoto({photo_url: user.photo_url})
                                setIsOpen(true)
                              }}
                                style={{ cursor: "pointer", marginLeft: 10 }}
                                size={22}
                                color={"#001B22"}
                              />
                              <MdDelete
                                onClick={() => {
                                  confirm({
                                    title: 'Deseja excluir o Usuário?',
                                    icon: <ExclamationCircleFilled/>,
                                    content: `Após essa ação, o Usuário ${user.name} será excluido.`,
                                    onOk() {
                                      deleteUser(user);
                                    },
                                    onCancel() {},
                                      cancelText: "Cancelar",
                                  });
                                }}
                                  style={{ cursor: "pointer", marginLeft: 6 }}
                                  size={22}
                                  color={"#001B22"}
                                />
                                </div>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </Table>
                </Scroll>
                  <center style={{marginTop: 15}}>
                    <Pagination simple defaultCurrent={page + 1} onChange={(page) => {
                      setPage(page - 1)
                   }} total={usersTotal} pageSize={30} 
                   showTotal={(total) => `${total} usuários`}/>
                  </center>
                </>
              )
              : (
                <div className="error">
                  <p>Nenhum usuário encontrado</p>
                </div>
              )}
        </>
      )}

      {isLoading && <Loader />}
      <Modal title={id ? "Editar Usuário" : "Novo Usuário"} width={500} confirmLoading={isLoadingModal} open={isOpen} okText={id ? "EDITAR USUÁRIO" : "CADASTRAR USUÁRIO"} cancelText="FECHAR" onOk={() => { 
        if (id) {
          editUser()
        } else {
          createUser()
        }  
      }} onCancel={() => {
        setIsOpen(false)
      }}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: 25}}>
        <div style={{ marginTop: 0, display: "flex", width: "100%", alignItems: "end", height: 120, borderRadius: 10, marginBottom: 25, marginBottom: 20, justifyContent: "center" }}>
            <img style={{ width: 120, marginLeft: 50, height: 120, borderRadius: 10, background: "#fff", }} src={photo.photo_url ? photo.photo_url : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__480.png"} />
            <button style={{
              width: 50, height: 50, border: "none",
              background: "transparent"
            }}>
              <label for="avatar" style={{ display: "flex", width: 50, height: 50, justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                <MdAddPhotoAlternate color="#001B22" size={25} />
                <input id="avatar" style={{ display: "none" }} type="file" accept="image/png,image/jpeg"   onChange={(file) => {
                  let photo = file.target.files[0]
                  photo.photo_url = URL.createObjectURL(file.target.files[0])
                  setPhoto(photo)
                }
                }
                />
              </label>
            </button>
          </div>
          <ViewInput>
            <p>Nome*</p>
            <Input
              placeholder="nome"
              value={name}
              onChange={(event) => setName(event.target.value)} />
          </ViewInput>
          <ViewInput>
            <p>Email*</p>
            <Input
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)} />
          </ViewInput>
          {!id && (
            <ViewInput>
              <p>Senha*</p>
              <Input
                placeholder="Email"
                value={password}
                onChange={(event) => setPassword(event.target.value)} />
            </ViewInput>
          )}
          <ViewInput>
            <p>Telefone*</p>
            <InputTel mask="(99) 99999-9999" maskChar="" noSpaceBetweenChars={true}
              value={phone_number} placeholder={"telefone"}
              onChange={(text) => { setPhone_number(text.target.value.replace(/[^0-9]/g, '')) }} />
          </ViewInput>
          <ViewInput>
                      <p>Selecione a Sala*</p>
                <Select
                  options={rooms}
                  placeholder="selecione a sala"
                        style={{width: "100%", textAlign: "left"}}
                        value={room || null}
                        onChange={(room) => setRoom(room)} />
                    </ViewInput>
          <ViewInput>
            <p>Data de Nascimento*</p>
            <DatePicker
              locale={locale}
                placeholder="data de nascimento"
                format={"DD/MM/YYYY"}
                maxDate={dayjs(new Date())}
                value={birthday ? dayjs(birthday) : ""}
                style={{
                    width: "100%",
                    height: 32,
                    display: 'flex',
                    alignItems: 'center',
                }}

                onChange={(date, d) => setBirthday(date)}
              />
          </ViewInput>
          <ViewInput>
            <p>Observação</p>
            <TextArea
              placeholder="deixe uma observação"
              autoSize={{ maxRows: 5}}
              style={{
                  minWidth: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 7
              }}
              value={observation}
              onChange={(event) => setObservation(event.target.value)} />
          </ViewInput>
        </div>
      </Modal>
    </Container>
  );
};

export default Users;
